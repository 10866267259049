<template>
  <div class="wrapper1">
    <el-row :gutter="20">
      <el-col :span="5">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名搜索"
        ></el-input>
      </el-col>
      <el-col :span="5">
        <el-select v-model="curRoleId" filterable placeholder="请选择">
          <el-option :key="-1" label="全部" :value="-1"></el-option>
          <el-option
            v-for="item in roleList"
            :key="item.role_id"
            :label="item.role_name"
            :value="item.role_id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="success" @click="searchUser" icon="el-icon-search"
          >搜索</el-button
        >
      </el-col>
      <el-col :span="3">
        <el-button type="success" @click="addUser" icon="el-icon-plus"
          >新增用户</el-button
        >
      </el-col>
      <el-col :span="2">
        <el-button @click="refresh" icon="el-icon-refresh" type="warning"
          >刷新</el-button
        >
      </el-col>
    </el-row>

    <table-container :list="userLists">
      <el-table-column type="index"> </el-table-column>
      <el-table-column prop="user_id" label="用户ID"> </el-table-column>
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="role_name" label="角色"> </el-table-column>
      <el-table-column prop="email" label="邮件"> </el-table-column>
      <el-table-column prop="mobile" label="电话"> </el-table-column>
      <el-table-column prop="status" label="是否有效">
        <template slot-scope="scope">
          <div>{{ scope.row.status == 1 ? "有效" : "无效" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建日期"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <!--                    <el-button type="text"-->
          <!--                               size="mini"-->
          <!--                               icon="el-icon-edit"-->
          <!--                               @click="handleChangePass(scope.$index, scope.row)">修改密码</el-button>-->
          <el-button
            :type="scope.row.status == 1 ? 'danger' : 'primary'"
            class="red"
            size="mini"
            @click="handleBan(scope.$index, scope.row)"
            >{{ scope.row.status == 1 ? "禁用" : "激活" }}</el-button
          >
        </template>
      </el-table-column>
    </table-container>
    <el-pagination
      background
      :hide-on-single-page="true"
      :total="totalCount"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="prev, pager, next,sizes"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

    <el-dialog
      :title="userDialogTitle"
      :visible.sync="addingUser"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        :label-position="labelPosition"
        label-width="90px"
        :model="addUserModel"
        :rules="addUserModelRules"
        ref="addUserModelRef"
      >
        <el-form-item label="用户ID:">
          <el-input v-model="addUserModel.user_id" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="角色:" prop="role_id">
          <el-select
            v-model="addUserModel.role_id"
            style="display: block"
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleList"
              :key="item.role_id"
              :label="item.role_name"
              :value="item.role_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名:" prop="username">
          <el-input
            v-model="addUserModel.username"
            placeholder="输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input
            v-model="addUserModel.password"
            show-password
            placeholder="输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-input v-model.trim="addUserModel.email"></el-input>
        </el-form-item>
        <el-form-item label="电话:" prop="phone">
          <el-input v-model.trim="addUserModel.phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd">取 消</el-button>
        <el-button type="primary" @click="sureAdd">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="changePassTitle"
      :visible.sync="changingPassword"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        :label-position="labelPosition"
        label-width="90px"
        :model="changePassModel"
        :rules="changePassModelRules"
        ref="changePassModelRef"
      >
        <el-form-item label="密码:">
          <el-input
            v-model="changePassModel.password"
            show-password
            placeholder="输入密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelChange">取 消</el-button>
        <el-button type="primary" @click="sureChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  inject: ["reload"],
  data() {
    return {
      userDialogTitle: "新增用户",
      labelPosition: "right",
      changingPassword: false,
      labelPosition: "right",
      roleList: [],
      curRoleId: -1,
      searchText: "",
      page: 0,
      pageSize: 20,
      totalCount: 0,
      pageSizes: [10, 20, 30, 40, 50],
      userLists: [],
      editing: false,
      addingUser: false,
      addUserModel: {
        role_id: "",
        user_id: "",
        username: "",
        password: "",
        email: "",
        mobile: "",
      },
      addUserModelRules: {
        role_id: [{ required: true, message: "请选择角色", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      addUserModelRulesBack: {
        role_id: [{ required: true, message: "请选择角色", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      changePassTitle: "",
      changePassModel: {
        user_id: "",
        user_name: "",
        password: "",
      },
      changePassModelRules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getRoleList();
    this.getUserList();
  },
  methods: {
    handleEdit(index, row) {
      this.addingUser = true;
      this.editing = true;
      this.userDialogTitle = "编辑用户";
      this.addUserModelRules = {};
      this.addUserModel = {
        role_id: row.role_id,
        user_id: row.user_id,
        username: row.username,
        password: "",
        email: row.email,
        mobile: row.mobile,
      };
    },
    handleChangePass(index, row) {
      this.changingPassword = true;
      this.changePassTitle = "修改 [" + row.user_name + "]账号密码";
      this.changePassModel = {
        user_id: row.user_id,
        user_name: row.user_name,
        password: "",
      };
    },
    async handleBan(index, row) {
      let status = 1;
      let msg = "禁用成功";
      if (row.status == 1) {
        msg = "禁用成功";
        status = 0;
      } else {
        msg = "激活成功";
        status = 1;
      }
      let param = {
        user_id: row.user_id,
        status: status,
      };
      let data = await this.$api.sysUserApi.banUser(param);
      if (data.code == 0) {
        this.$message({
          message: msg,
          type: "success",
        });
        this.reload();
      } else {
        this.$message({
          message: "新增失败",
          type: "error",
        });
        return;
      }
    },
    cancelAdd() {
      this.addingUser = false;
      this.userDialogTitle = "新增用户";
      this.editing = false;
      this.addUserModel = {
        role_id: "",
        user_id: "",
        username: "",
        password: "",
        email: "",
        mobile: "",
      };
    },
    sureAdd() {
      let that = this;
      this.$refs.addUserModelRef.validate(async (valid) => {
        if (valid) {
          let data = null;
          if (that.editing == true) {
            data = await this.$api.sysUserApi.updateUser(this.addUserModel);
          } else {
            data = await this.$api.sysUserApi.newUser(this.addUserModel);
          }
          if (data.code != 0) {
            this.$message({
              message: data.msg,
              type: "warning",
            });
            return;
          }
          if (data.code == 0) {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.addingUser = false;
            this.userDialogTitle = "新增用户";
            this.editing = false;
            this.reload();
          }
        } else {
          this.$message({
            message: "参数缺失",
            type: "error",
          });
          return;
        }
      });
    },
    cancelChange() {
      this.changingPassword = false;
    },
    sureChange() {
      this.changingPassword = false;
    },
    addUser() {
      this.addingUser = true;
      this.addUserModel.username = "";
      this.addUserModel.password = "";
      this.addUserModel.user_id = "";
      this.addUserModel.email = "";
      this.addUserModel.mobile = "";
      this.addUserModel.role_id = "";
    },
    searchUser() {
      this.getUserList();
    },
    refresh() {
      this.reload();
    },
    async getRoleList() {
      let param = {
        page: 0,
        pageSize: 0,
      };
      let data = await this.$api.sysUserApi.getRoleList(param);
      if (data.code == 0) {
        let d = data;
        this.roleList = d.roles;
      } else if (data.code != 0) {
        if (data.code == 500) {
          this.$message({
            message: data.msg,
            type: "error",
          });
          return;
        }
      }
    },
    async getUserList() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        role_id: this.curRoleId,
        searchText: this.searchText,
      };
      let data = await this.$api.sysUserApi.getUserList(param);
      if (data.code == 0) {
        let d = data;
        this.userLists = d.users;
        this.page = d.page;
        this.pageSize = d.pageSize;
        this.totalCount = d.totalCount;
      } else if (data.code != 0) {
        if (data.code == 500) {
          this.$message({
            message: data.msg,
            type: "error",
          });
          return;
        }
      }
    },
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getUserList();
    },
    currentChange(page) {
      this.page = page;
      this.getUserList();
    },
  },
};
</script>

<style scoped></style>
